<template>
  <DashboardCard
    :loading="loading"
    :title="$t('terms.supplierTerms.purchaseHistoryTable.purchases')"
    :action-type="CARD_ACTIONS.TWO_SELECT_LIST_INPUTS"
    :list-two="listOfOptions"
    :should-close-selects-on-scroll="true"
    @on-two-select-list-update="updatePurchasesListener"
  >
    <table class="table">
      <tbody>
        <tr v-for="row in rows" :key="row.header" class="row-container">
          <td class="header">{{ row.header }}</td>
          <td>{{ row.firstValue }}</td>
          <td>{{ row.secondValue }}</td>
        </tr>
      </tbody>
    </table>
  </DashboardCard>
</template>

<script>
import { computed, ref, watch, getCurrentInstance } from 'vue';

import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/locale/useCurrency';

import { useSupplierDataCard } from '../../compositions/orderItemAggregation';
import { PERIOD_OPTIONS } from '../purchaseData/tools/constants';
import { getGraphPeriod } from '../purchaseData/tools/utils';

import { DashboardCard, CARD_ACTIONS } from '@/modules/core';

import { formatCurrencyOrDash, formatNumberOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';

import {
  getPeriodOptions,
  convertPeriodOptionToSelectOption,
} from '@/modules/order/components/purchaseData/tools/utils';

export default {
  components: { DashboardCard },
  props: {
    supplierId: { type: String, default: null },
    hideOrderCount: { type: Boolean, default: false },
    hideAverageOrderAmount: { type: Boolean, default: false },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { currencyFormat: currencyRawFormat } = useCurrency();

    const currencyFormat = computed(() => ({
      ...currencyRawFormat.value,
      maximumFractionDigits: 0,
    }));

    const selectedPeriodOption = ref(PERIOD_OPTIONS.CURRENT_MONTH);
    const selectedCompareOption = ref(null);
    const listOfOptions = getPeriodOptions(convertPeriodOptionToSelectOption).filter(
      ({ value }) => value !== PERIOD_OPTIONS.ALL_TIME
    );

    const periodAggregationDateRange = computed(() => getGraphPeriod(selectedPeriodOption.value));
    const { aggregations: periodAggregations, loading: periodAggregationLoading } = useSupplierDataCard(
      computed(() => ({
        businessId: currentTenant.value?.id,
        supplierId: props.supplierId,
        fromDate: periodAggregationDateRange.value?.fromDate,
        toDate: periodAggregationDateRange.value?.toDate,
        monthlyAverage: selectedPeriodOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE ? true : null,
        selectedPeriod: periodAggregationDateRange.value,
      }))
    );
    const periodSelectGroupBySupplier = computed(() => {
      if (periodAggregations.value.length) {
        const aggregationValue = computed(() => periodAggregations.value[0]);

        if (selectedPeriodOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE) {
          return {
            sumPrice: aggregationValue.value.monthlyAverageSumTotalNetPricePerUnit,
            numberOfProducts: '-',
            numberOfOrders: aggregationValue.value.monthlyAverageNumberOfOrders,
          };
        }
        return {
          ...aggregationValue.value,
          numberOfProducts: aggregationValue.value.numberOfProducts,
        };
      }
      return {
        sumPrice: 0,
        numberOfProducts: 0,
        numberOfOrders: 0,
      };
    });

    const compareAggregationDateRange = computed(() => getGraphPeriod(selectedCompareOption.value));
    const { aggregations: compareAggregations, loading: compareAggregationLoading } = useSupplierDataCard(
      computed(() => ({
        businessId: currentTenant.value?.id,
        supplierId: props.supplierId,
        fromDate: compareAggregationDateRange.value?.fromDate,
        toDate: compareAggregationDateRange.value?.toDate,
        monthlyAverage: selectedCompareOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE ? true : null,
        selectedPeriod: selectedCompareOption.value,
      }))
    );

    const compareSelectGroupBySupplier = computed({
      set: (value) => value,
      get: () => {
        if (!selectedCompareOption.value) return {};

        if (compareAggregations.value.length) {
          const aggregationValue = computed(() => compareAggregations.value[0]);

          if (selectedCompareOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE) {
            return {
              sumPrice: aggregationValue.value.monthlyAverageSumTotalNetPricePerUnit,
              numberOfProducts: '-',
              numberOfOrders: aggregationValue.value.monthlyAverageNumberOfOrders,
            };
          }

          return {
            ...aggregationValue.value,
            numberOfProducts: aggregationValue.value.numberOfProducts,
          };
        }
        return {
          sumPrice: 0,
          numberOfProducts: 0,
          numberOfOrders: 0,
        };
      },
    });

    watch(
      selectedPeriodOption,
      () => {
        selectedCompareOption.value = null;
      },
      { immediate: true }
    );

    const rows = computed(() => [
      {
        header: root.$i18n.t('terms.supplierTerms.purchaseHistoryTable.amount'),
        firstValue: formatCurrencyOrDash(periodSelectGroupBySupplier.value?.sumPrice, currencyFormat.value),
        secondValue: formatCurrencyOrDash(compareSelectGroupBySupplier.value?.sumPrice, currencyFormat.value),
      },
      ...(props.hideOrderCount
        ? []
        : [
            {
              header: root.$i18n.t('terms.supplierTerms.purchaseHistoryTable.orders'),
              firstValue: formatNumberOrDash(periodSelectGroupBySupplier.value?.numberOfOrders),
              secondValue: formatNumberOrDash(compareSelectGroupBySupplier.value?.numberOfOrders),
            },
          ]),
      ...(props.hideAverageOrderAmount
        ? []
        : [
            {
              header: root.$i18n.t('terms.supplierTerms.purchaseHistoryTable.averageOrderAmount'),
              firstValue: formatCurrencyOrDash(
                periodSelectGroupBySupplier.value?.sumPrice / (periodSelectGroupBySupplier.value?.numberOfOrders || 1),
                currencyFormat.value
              ),
              secondValue: formatCurrencyOrDash(
                compareSelectGroupBySupplier.value?.sumPrice /
                  (compareSelectGroupBySupplier.value?.numberOfOrders || 1),
                currencyFormat.value
              ),
            },
          ]),
      {
        header: root.$i18n.t('terms.supplierTerms.purchaseHistoryTable.productsOrderedCount'),
        firstValue: formatNumberOrDash(periodSelectGroupBySupplier.value?.numberOfProducts),
        secondValue: formatNumberOrDash(compareSelectGroupBySupplier.value?.numberOfProducts),
      },
    ]);

    const updatePurchasesListener = (data) => {
      const { listOneValue, listTwoValue } = data;
      selectedPeriodOption.value = listOneValue.value;
      selectedCompareOption.value = listTwoValue.value;
    };

    return {
      CARD_ACTIONS,
      rows,
      selectedPeriodOption,
      selectedCompareOption,
      listOfOptions,
      updatePurchasesListener,
      loading: computed(() => periodAggregationLoading.value || compareAggregationLoading.value),
    };
  },
};
</script>

<style scoped lang="scss">
.header {
  font-weight: 450;
  width: 150px;
}
td {
  padding: 0;
  border: 0;
}
.ml-1 {
  margin-left: 45px;
}
.row-container {
  display: flex;
  justify-content: space-between;
  line-height: 104%;
  font-size: 14px;
  border-top: 1px solid #f3f3f4;
  padding: 0.75rem 0.5rem;
}
</style>
