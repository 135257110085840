<template>
  <PurchaseCardLayout :loading="loading" :period.sync="selectedPeriodOption" :compare.sync="selectedCompareOption">
    <template #table>
      <div
        v-for="(row, rowIndex) in rows"
        :key="row.header"
        :class="{ 'row-container': true, 'top-border': rowIndex !== 0 }"
      >
        <el-tooltip :content="row.header" :placement="$t('direction') === 'rtl' ? 'top-end' : 'top-start'">
          <div class="header">
            {{ row.header }}
          </div>
        </el-tooltip>
        <div class="data-container">
          <div v-if="!loading">{{ row.firstValue }}</div>
          <div v-if="!loading">{{ row.secondValue }}</div>
        </div>
      </div>
    </template>
  </PurchaseCardLayout>
</template>

<script>
import { computed, ref, watch, getCurrentInstance } from 'vue';

import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/locale/useCurrency';

import { formatCurrencyOrDash, formatNumberOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';

import { useProductDataCard } from '../../compositions/orderItemAggregation';
import { PERIOD_OPTIONS } from '../purchaseData/tools/constants';
import PurchaseCardLayout from './PurchaseCardLayout';
import { getGraphPeriod } from '../purchaseData/tools/utils';

export default {
  components: { PurchaseCardLayout },
  props: {
    productId: { type: String, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { currencyFormat: currencyRawFormat } = useCurrency();

    const currencyFormat = computed(() => ({
      ...currencyRawFormat.value,
      maximumFractionDigits: 0,
    }));

    const selectedPeriodOption = ref(PERIOD_OPTIONS.CURRENT_MONTH);
    const selectedCompareOption = ref(null);

    const periodAggregationDateRange = computed(() => getGraphPeriod(selectedPeriodOption.value));

    const { aggregations: periodAggregations, loading: periodAggregationLoading } = useProductDataCard(
      computed(() => ({
        businessId: currentTenant.value.id,
        productId: props.productId,
        fromDate:
          selectedPeriodOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE
            ? null
            : periodAggregationDateRange.value?.fromDate,
        toDate:
          selectedPeriodOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE
            ? null
            : periodAggregationDateRange.value?.toDate,
        monthlyAverage: selectedPeriodOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE ? true : null,
        selectedPeriod: selectedPeriodOption.value,
      }))
    );

    const periodSelectGroupBySupplier = computed(() => {
      if (periodAggregations.value.length) {
        if (selectedPeriodOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE) {
          const currentValue = periodAggregations.value[0];
          return {
            sumPrice: currentValue.monthlyAverageSumTotalNetPricePerUnit,
            quantity: currentValue.monthlyAverageTotalQuantity,
            numberOfOrders: currentValue.monthlyAverageNumberOfOrders,
            pricedOrderItemAverage:
              currentValue.monthlyAverageSumTotalNetPricePerUnit / currentValue.monthlyAverageTotalQuantity,
          };
        }
        return periodAggregations.value[0];
      }
      return {
        sumPrice: 0,
        quantity: 0,
        numberOfOrders: 0,
      };
    });

    const compareAggregationDateRange = computed(() => getGraphPeriod(selectedCompareOption.value));
    const { aggregations: compareAggregations, loading: compareAggregationLoading } = useProductDataCard(
      computed(() => ({
        businessId: currentTenant.value.id,
        productId: props.productId,
        fromDate:
          selectedCompareOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE
            ? null
            : compareAggregationDateRange.value?.fromDate,
        toDate:
          selectedCompareOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE
            ? null
            : compareAggregationDateRange.value?.toDate,
        monthlyAverage: selectedCompareOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE ? true : null,
        selectedPeriod: selectedCompareOption.value,
      }))
    );

    const compareSelectGroupBySupplier = computed({
      set: (value) => value,
      get: () => {
        if (!selectedCompareOption.value) return {};

        if (compareAggregations.value.length) {
          if (selectedCompareOption.value === PERIOD_OPTIONS.MONTHLY_AVERAGE) {
            const currentValue = compareAggregations.value[0];
            return {
              sumPrice: currentValue.monthlyAverageSumTotalNetPricePerUnit,
              quantity: currentValue.monthlyAverageTotalQuantity,
              numberOfOrders: currentValue.monthlyAverageNumberOfOrders,
              pricedOrderItemAverage:
                currentValue.monthlyAverageSumTotalNetPricePerUnit / currentValue.monthlyAverageTotalQuantity,
            };
          }
          return compareAggregations.value[0];
        }
        return {
          sumPrice: 0,
          quantity: 0,
          numberOfOrders: 0,
        };
      },
    });

    watch(
      selectedPeriodOption,
      () => {
        selectedCompareOption.value = null;
      },
      { immediate: true }
    );
    const rows = computed(() => [
      {
        header: root.$i18n.t('order.ProductDataCard.amount'),
        firstValue: formatCurrencyOrDash(periodSelectGroupBySupplier.value?.sumPrice, currencyFormat.value),
        secondValue: formatCurrencyOrDash(compareSelectGroupBySupplier.value?.sumPrice, currencyFormat.value),
      },
      {
        header: root.$i18n.t('order.ProductDataCard.quantity'),
        firstValue: formatNumberOrDash(periodSelectGroupBySupplier.value?.quantity),
        secondValue: formatNumberOrDash(compareSelectGroupBySupplier.value?.quantity),
      },
      {
        header: root.$i18n.t('order.ProductDataCard.averagePrice'),
        firstValue: formatCurrencyOrDash(periodSelectGroupBySupplier.value?.pricedOrderItemAverage, {
          ...currencyFormat.value,
          maximumFractionDigits: 2,
        }),
        secondValue: formatCurrencyOrDash(compareSelectGroupBySupplier.value?.pricedOrderItemAverage, {
          ...currencyFormat.value,
          maximumFractionDigits: 2,
        }),
      },
      {
        header: root.$i18n.t('order.ProductDataCard.orders'),
        firstValue: formatNumberOrDash(periodSelectGroupBySupplier.value?.numberOfOrders),
        secondValue: formatNumberOrDash(compareSelectGroupBySupplier.value?.numberOfOrders),
      },
      {
        header: root.$i18n.t('order.ProductDataCard.averageOrderAmount'),
        firstValue: formatCurrencyOrDash(
          periodSelectGroupBySupplier.value?.sumPrice / periodSelectGroupBySupplier.value?.numberOfOrders,
          currencyFormat.value
        ),
        secondValue: formatCurrencyOrDash(
          compareSelectGroupBySupplier.value?.sumPrice / compareSelectGroupBySupplier.value?.numberOfOrders,
          currencyFormat.value
        ),
      },
      {
        header: root.$i18n.t('order.ProductDataCard.averageOrderQuantity'),
        firstValue: formatNumberOrDash(
          periodSelectGroupBySupplier.value?.qunatity / periodSelectGroupBySupplier.value?.numberOfOrders
        ),
        secondValue: formatNumberOrDash(
          compareSelectGroupBySupplier.value?.qunatity / compareSelectGroupBySupplier.value?.numberOfOrders
        ),
      },
    ]);

    return {
      rows,
      selectedPeriodOption,
      selectedCompareOption,
      loading: computed(() => periodAggregationLoading.value || compareAggregationLoading.value),
    };
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
  line-height: 104%;
  font-size: 14px;

  .selects-container {
    display: grid;
    grid-template-columns: 120px 120px;
    gap: 8px;
  }

  .title {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.row-container {
  display: flex;
  justify-content: space-between;
  line-height: 104%;
  padding: 8px 0;
  font-size: 14px;

  &.top-border {
    border-top: 1px solid #dce0e8;
  }

  .header {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .data-container {
    display: grid;
    grid-template-columns: 1fr 120px;
    gap: 8px;

    div {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
../../compositions/orderItemAggregation
