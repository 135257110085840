<template>
  <div v-loading="loading" class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div class="fw-bold">{{ $t('terms.supplierTerms.purchaseHistoryTable.purchases') }}</div>
      <div class="selects-container">
        <el-select :value="period" size="small" @change="$emit('update:period', $event)">
          <el-option v-for="option in selectOption" :key="option.value" :label="option.label" :value="option.value" />
        </el-select>

        <el-select
          :value="compare"
          :class="$t('direction') === 'rtl' ? 'me-1' : 'ms-1'"
          :disabled="!availableCompareOptions.length"
          size="small"
          @change="$emit('update:compare', $event)"
          ><el-option
            v-for="compareOption in availableCompareOptions"
            :key="compareOption.value"
            :label="compareOption.label"
            :value="compareOption.value"
          />
        </el-select>
      </div>
    </div>
    <div class="card-body">
      <slot name="table" />
    </div>
  </div>
</template>

<script>
import { PERIOD_OPTIONS } from './tools/constants';
import { getPeriodOptions, getAvailableCompareOptions, convertPeriodOptionToSelectOption } from './tools/utils';

export default {
  props: {
    period: { type: String, default: null },
    compare: { type: String, default: null },
    loading: { type: Boolean, default: false },
  },
  computed: {
    availableCompareOptions() {
      return getAvailableCompareOptions(this.period).map(convertPeriodOptionToSelectOption);
    },
    selectOption() {
      return getPeriodOptions(convertPeriodOptionToSelectOption).filter(
        ({ value }) => value !== PERIOD_OPTIONS.ALL_TIME
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.selects-container {
  display: grid;
  grid-template-columns: 120px 120px;
  gap: 8px;
}
</style>
